import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import { LocationWrapper } from '../../components/LocationWrapper';
import { SinglePageContent } from '../../components/Restaurant/SinglePageContent';
import briefCase from '../../assets/images/restaurant/suitcase_outline.svg';
import { ClockCircleOutlined, StarFilled, LoadingOutlined, StarOutlined } from '@ant-design/icons';
import {Col, Row, Spin, Drawer, Modal, Rate, Button, notification} from 'antd';
import { _single_restaurant } from "../../common/axios_services";
import {useNavigate, useParams} from "react-router-dom";
import placeholderImage from '../../assets/images/homepage/restaurant_food.png';
import RightDrawerTemplate from "../../components/RightDrawerTemplate";




const SinglePage = () => {
  const { id: restaurantId } = useParams();
  const [loading, setLoading] = useState(true);
  const [restaurantRating, setRestaurantRating] = useState("");
  const [restaurantData, setRestaurantData] = useState({});
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userRating, setUserRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const Navigate = useNavigate();

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      description: message,
    });
  };


  // Function to show the drawer
  const showAboutDrawer = () => {
    setIsDrawerVisible(true);
  };

  // Function to hide the drawer
  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  // Function to show the rating modal
  const showRatingModal = () => {
    setIsModalVisible(true);
  };

  // Function to hide the rating modal
  const closeRatingModal = () => {
    setIsModalVisible(false);
    setUserRating(0);
    setFeedback("");
  };

  const submitRating = () => {
    console.log(`Rating: ${userRating}, Feedback: ${feedback}`);
    // Here, you can add the logic to send the user's rating and feedback to the backend.
    closeRatingModal();
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  // Fetch restaurant info
  const fetchRestaurantInfo = async (id) => {
    try {
      const response = await _single_restaurant({ id });
      const restaurant = response.data.data;
      setRestaurantData(restaurant);
      setRestaurantRating(restaurant.rating || "NA");
      setLoading(false);
    } catch (err) {
      openNotificationWithIcon("Something went wrong", err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (restaurantId) {
      fetchRestaurantInfo(restaurantId);
    }
  }, [restaurantId]);

  const getReadyTime = () => {
    const { min_ready_time, max_ready_time } = restaurantData;
    if (!min_ready_time || !max_ready_time) return "NA";
    return `${parseInt(min_ready_time)}-${parseInt(max_ready_time)} mins`;
  };

  const getRestaurantImage = () => {
    if (restaurantData.display_image) {
      return restaurantData.display_image;
    }
    const kitchenImage = restaurantData.kitchen_images?.find(img => img.url);
    return kitchenImage ? kitchenImage.url : placeholderImage;
  };


  return (
    <>
      <Spin spinning={loading} indicator={antIcon}>
        <div className=" h-auto">
          <Navbar noShadow={true} adjustNavSize={true}  />
          <LocationWrapper />
          <div className=" singlePageHeader ">
            <Row justify={'center'} >
              <Col xs={22} lg={24}>
                <div className="flex items-center flex-wrap justify-between gap-2 lg:px-20 py-5">
                  <h1 className="text-[#44843F] text-5xl">{restaurantData.name}</h1>
                  <div className="flex gap-5 md:gap-20 ">
                    <div className="flex flex-col">
                      <p className='text-[#959595]  '>Profile</p>
                      <div className="flex gap-3 items-center" onClick={showAboutDrawer} style={{cursor: "pointer"}}>
                        <img src={briefCase} className="w-6" alt=""/>
                        <p className="text-[12px] md:text-[16px] font-semibold underline">About Us</p>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <p className='text-[#959595]'>Time</p>
                      <div className="flex gap-3 items-center ">
                        <ClockCircleOutlined style={{fontSize: '15px'}}/>
                        <p className="text-[12px] md:text-[16px] font-semibold">
                          {loading ? "NA" : getReadyTime()}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <p className='text-[#959595]'>Rating</p>
                      <div className="flex gap-3 items-center ">
                        <StarFilled style={{fontSize: '15px', color: '#FF8900'}}/>
                        <p className="text-[12px] md:text-[16px] font-semibold">
                          {loading ? "NA" : restaurantRating}
                        </p>
                      </div>
                    </div>
                    <button className={"py-2 px-10 text-[14px] font-bold bg-[#44843F] rounded-xl text-white"}
                            onClick={showRatingModal}>
                      Rate Us
                    </button>
                  </div>
                </div>
                {/* Restaurant Img */}
                <div className="lg:px-20">
                  <div className="singleRestaurant_img flex mx-auto mt-2">
                    <img
                        src={getRestaurantImage()}
                        alt={restaurantData.name}
                        className="w-full h-[300px] rounded-3xl object-cover"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="lg:px-20 pt-20">
            <SinglePageContent
                restaurantName={restaurantData.name}
                city={restaurantData.city}
                restaurantImg={restaurantData.display_image}
            />
          </div>
          {/* <Footer /> */}
        </div>
      </Spin>

      {/*About US DRAWER*/}
      <RightDrawerTemplate closeDrawer={closeDrawer}
                           openDrawer={isDrawerVisible}
                           width={450} title={"About Us"}>
        <div>
          <h2 className="text-green-800 font-bold flex items-center my-4 mt-8 text-5xl ">
            {restaurantData.name}
          </h2>
          <div className="singleRestaurant_img flex mx-auto mt-2">
            <img
                src={getRestaurantImage()}
                alt={restaurantData.name}
                className="w-full h-[250px] object-cover rounded-lg"
            />
          </div>
          <div className={"description mt-8"}>

            <p className={"my-8"} >{restaurantData.description || "No description available for this restaurant."}</p>

            <div className="flex flex-col gap-y-5">
              <div>
                <h2>ALLERGENS </h2>
                <p>You can call {restaurantData.name} to ask about their ingredients and allergen
                  information, production or cooking methods.
                  Call {restaurantData.name} on <span className={"font-bold"}>+{restaurantData.contact_phone_code}{restaurantData.contact_phone}</span>
                </p>
              </div>
              <div>
                <h2>HYGIENE RATING</h2>
                <p>
                  Restaurant food hygiene rating is a good standing meaning the kitchen has good hygiene practices.

                </p>
              </div>
              <div>
                <h2>LOCATION</h2>
                <p> {`${restaurantData.address}, ${restaurantData.city} ${restaurantData.state}`}</p>
              </div>
              <div className='restaurant_profile_note'>
                <h2>NOTES</h2>
                <p>All dishes may contain traces of the following allergens: Gluten, Crustaceans,
                  Eggs, Fish, Peanuts, Soybeans, Milk, Nuts (e.g. almonds, hazelnuts, walnuts, cashews), For any
                  questions regarding the allergen contents of specific dishes please contact the restaurant
                  directly.</p>
              </div>
            </div>
          </div>
        </div>
      </RightDrawerTemplate>

      {/* Rating Modal */}
      <Modal
          title={`Rate ${restaurantData.name}`}
          open={isModalVisible}
          onCancel={closeRatingModal}
          onOk={submitRating}
          okText="Submit"
          okButtonProps={{
            style: {backgroundColor: '#44843F', borderColor: '#44843F', color: '#fff'},
          }}
      >
        <div className="flex flex-col gap-4">
          <Rate value={userRating} onChange={(value) => setUserRating(value)}/>
          <textarea
              className="w-full p-2 border rounded"
              placeholder="Leave your feedback..."
              rows={4}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
          />
        </div>
      </Modal>
    </>
  )
}

export default SinglePage